<template>
  <div class="tw-flex tw-h-dvh">
    <div class="tw-w-[320px] tw-min-w-[320px] tw-h-full tw-p-4 tw-border-r tw-border-[#F0F0F0] tw-flex tw-flex-col">
      <zem-card-title class="tw-mt-4">
        Модули и сущности
        <zem-link class="tw-text-xs tw-text-[#0DB2B2] tw-font-semibold" @click="resetAll">Сбросить все</zem-link>
      </zem-card-title>
      <div class="tw-relative">
        <zem-input placeholder="Поиск" v-model="search" />
        <img :src="require('@/assets/icons/search.svg')" alt="" class="tw-size-3 tw-absolute tw-right-2 tw-top-2" />
      </div>
      <div class="tw-mt-5 tw-flex-grow tw-overflow-y-auto">
        <zem-collapse
          v-for="(schema, schemaIndex) in filteredModules"
          :key="schemaIndex"
          :arrow="false"
          :title="schema.section"
          class="mt-0"
          class-header="tw-pl-0"
          class-body="tw-pb-2"
          not-border
        >
          <ul class="tw-text-sm tw-space-y-2.5 tw-pl-1 tw-text-[#3E3E3E]">
            <li
              v-for="(container, containerIndex) in schema['containers']"
              :key="containerIndex"
              @click="selectModule(container.container)"
              class="tw-cursor-pointer"
              :class="{'tw-text-[#0DB2B2] tw-font-semibold': selectedModule === container.container}"
            >
              {{ container.container }}
            </li>
          </ul>
        </zem-collapse>
      </div>
    </div>
    <div class="accesses-container tw-flex-grow">
      <zem-card>
        <div>
          <template>
            <zem-card-title v-if="newElement" class="mb-0">Создание роли</zem-card-title>
            <template v-else>
              <zem-card-title>Роль: {{ currentItem['display_name'] }}</zem-card-title>
              <div class="block-id">
                <span class="block-id__label"></span>
                ID {{ currentItem['number'] }}
              </div>
              <div v-if="!closeAcc([3])" class="block-id">
                <span class="block-id__label second"></span>
                ID {{ currentItem['id'] }}
              </div>
            </template>
          </template>
        </div>
        <div class="tw-flex tw-gap-x-2">
          <template v-if="!edit && !newElement">
            <img
              v-if="$can('edit', 'role-update')"
              :src="require('@/assets/icons/edit.svg')"
              alt=""
              class="block-id__edit"
              @click="edit = true"
            />
            <img
              :src="require('@/assets/icons/close.svg')"
              alt=""
              class="block-id__delete tw-ml-0"
              @click="closeRightSidebar"
            />
          </template>
          <template v-else>
            <img
              :src="require('@/assets/icons/close.svg')"
              alt=""
              class="block-id__delete tw-ml-0 mobile-d"
              @click="closeRightSidebar"
            />
            <zem-link class="mobile-d-none" @click="closeRightSidebar">Отменить</zem-link>
            <zem-button :loading="loadingNewElement" @click="$emit(!edit ? 'on-create' : 'on-update')">
              Сохранить
            </zem-button>
          </template>
        </div>
      </zem-card>

      <div class="zem-collapse-table rows mt-3 mb-3">
        <div class="zem-collapse-table__row" style="grid-template-columns: 1fr 2fr">
          <div class="zem-collapse-table__column title mt-1">Уникальный псевдоним</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            {{ currentItem['name'] }}
          </div>
          <ZemInput
            v-else
            :value="currentItem['name']"
            class="mt-0"
            type="text"
            @input="e => (currentItem['name'] = e)"
          />
          <div class="zem-collapse-table__column title mt-1">Отображаемое название</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            {{ currentItem['display_name'] }}
          </div>
          <ZemInput
            v-else
            :value="currentItem['display_name']"
            class="mt-0"
            type="text"
            @input="e => (currentItem['display_name'] = e)"
          />
          <div class="zem-collapse-table__column title mt-1">Описание</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            {{ currentItem['description'] }}
          </div>
          <ZemInput
            v-else
            :value="currentItem['description']"
            class="mt-0"
            type="text"
            @input="e => (currentItem['description'] = e)"
          />
        </div>
      </div>

      <ZemCheckbox v-model="isNewInterface" class="tw-mb-4"> Новый интерфейс </ZemCheckbox>

      <div v-if="!newElement && !$store.state.roles.isArchive && !isNewInterface" class="position-relative">
        <preloader-wrapper :show="schemaData.length === 0" class="mt-4" />
        <template v-for="(schema, schemaIndex) in schemaData">
          <zem-collapse :key="schemaIndex" :title="schema.section">
            <template v-for="(container, containerIndex) in schema['containers']">
              <zem-collapse
                v-if="schema['containers'].length > 0"
                :key="containerIndex"
                :arrow="false"
                :title="container.container"
                class="mt-0"
                not-border
              >
                <div class="zem-collapse-table rows">
                  <div
                    class="zem-collapse-table__row"
                    style="grid-template-columns: repeat(1, 1fr); gap: 10px; margin-left: 19px"
                  >
                    <template v-for="(permission, permissionIndex) in container.permissions">
                      <ZemCheckbox
                        :key="permissionIndex"
                        v-model="permission['is_assigned']"
                        :disabled="!edit || roleDisabled || currentItem.name === 'admin'"
                      >
                        <p v-tippy="{delay: 200, content: permission['description']}">
                          {{ permission['display_name'] }}
                        </p>
                      </ZemCheckbox>
                      <!-- <p class="text-normal text-muted">{{ permission['name'] }}</p> -->
                    </template>
                  </div>
                </div>
              </zem-collapse>
            </template>
          </zem-collapse>
        </template>
      </div>

      <div v-if="isNewInterface" class="tw-mt-4">
        <preloader-wrapper :show="!newSchemaData" class="mt-4" />
        <div
          v-for="(data, dataIndex) in newSchemaData.data"
          :key="dataIndex"
          class="tw-px-4 tw-py-3 tw-border tw-border-[#F0F0F0]"
          :class="{
            'tw-rounded-t tw-border-b-0': dataIndex === 0,
            'tw-rounded-b': dataIndex === newSchemaData.data.length - 1,
          }"
        >
          <ZemCheckbox v-model="data['assigned']" :class="{'tw-mb-4': data['schema'].length > 0}"
            ><span class="tw-text-sm tw-font-semibold">{{ data.title }}</span></ZemCheckbox
          >
          <div v-for="(schema, schemaIndex) in data['schema']">
            <!-- Условия -->
            <zem-collapse
              v-if="schema['conditions'].length > 0"
              :arrow="false"
              :title="schema.title"
              class="mt-0"
              class-header="tw-p-0 tw-pl-1 tw-h-auto tw-text-xs"
              class-title="tw-p-0"
              class-body="tw-px-0"
              not-border
            >
              <div>
                <!-- Блоки -->
                <zem-collapse
                  v-for="(condition, conditionIndex) in schema['conditions']"
                  :key="conditionIndex"
                  :title="condition.title"
                  not-border
                  :arrow="false"
                  class="tw-mt-2"
                  class-header="tw-h-auto tw-text-xs"
                  class-body="tw-px-0 tw-pb-0"
                >
                  <div
                    class="zem-collapse-table__row"
                    style="grid-template-columns: 1fr 1fr 20px; gap: 10px; margin-left: 14px"
                  >
                    <template v-for="(value, valueIndex) in condition.value">
                      <ZemDropdownList
                        :key="valueIndex"
                        :options="condition['allowed_lists'].map(item => ({title: item.title, value: item.key}))"
                        v-model="value['type']"
                        class="tw-mt-0"
                      />
                      <ZemMultiSelect
                        v-if="condition['allowed_lists'].find(item => item.key === value['type'].value).multiple"
                        :key="valueIndex"
                        :options="condition['allowed_lists'].map(item => ({title: item.title, value: item.key}))"
                        v-model="value['values']"
                      />
                      <ZemDropdownList
                        v-else
                        :options="condition['allowed_lists'].map(item => ({title: item.title, value: item.key}))"
                        v-model="value['values']"
                        class="tw-mt-0"
                      />
                      <button
                        class="tw-size-3 tw-fill-[#FF4D4F] tw-flex tw-items-center"
                        @click="removeCondition(dataIndex, schemaIndex, conditionIndex, valueIndex)"
                      >
                        <iXMark />
                      </button>
                    </template>
                    <button
                      class="tw-col-span-3 tw-fill-[#0DB2B2] tw-flex tw-items-center tw-gap-1"
                      :class="{'tw-mt-1': condition.value.length === 0}"
                      @click="addCondition(dataIndex, schemaIndex, conditionIndex)"
                    >
                      <iPlus class="tw-size-3" /><span class="tw-text-xs tw-font-semibold tw-text-[#0DB2B2]"
                        >Условие</span
                      >
                    </button>
                  </div>
                </zem-collapse>
                <div v-if="isNewGroup" class="tw-flex tw-gap-2 tw-items-center tw-mt-2">
                  <zem-input
                    v-model="titleNewGroup"
                    placeholder="Название группы"
                    @keyup.enter="addGroup(dataIndex, schemaIndex, conditionIndex)"
                  />
                  <button class="tw-size-3 tw-fill-[#FF4D4F]" @click="addGroup(dataIndex, schemaIndex, conditionIndex)">
                    <iCheck class="tw-size-3 tw-fill-[#0DB2B2]" />
                  </button>
                  <button class="tw-size-3 tw-fill-[#FF4D4F]" @click="isNewGroup = false">
                    <iXMark class="tw-size-3 tw-fill-[#FF4D4F]" />
                  </button>
                </div>
                <button
                  class="tw-col-span-3 tw-fill-[#0DB2B2] tw-flex tw-items-center tw-gap-[6px] tw-mt-2 tw-ml-[9px]"
                  @click="isNewGroup = true"
                >
                  <iPlus class="tw-size-3" /><span class="tw-text-xs tw-font-semibold tw-text-[#0DB2B2]">Группа</span>
                </button>
              </div>
            </zem-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard'
import ZemCardTitle from '@/components/ui/ZemCardTitle'
import ZemCollapse from '@/components/ui/ZemCollapse'
import ZemInput from '@/components/ui/ZemInput'
import ZemDropdownList from '@/components/ui/ZemDropdownList'
import ZemSelect from '@/components/ui/Select.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import {eventBus} from '@/main'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import ZemCheckbox from '@/components/ui/Checkbox.vue'
import {closeAcc} from '@/assets/scripts/scripts'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'
import iXMark from '@/components/icons/iXMark.vue'
import iPlus from '@/components/icons/iPlus.vue'
import iCheck from '@/components/icons/iCheck.vue'

export default {
  components: {
    PreloaderWrapper,
    ZemPreloader,
    ZemCheckbox,
    ZemRadioButton,
    ZemSelect,
    ZemCardTitle,
    ZemCard,
    ZemCollapse,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemLink,
    ZemButton,
    iXMark,
    iPlus,
    iCheck,
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {},
    },
    newElement: {
      type: Boolean,
      default: false,
    },
    loadingNewElement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      newElementTitle: '',
      data: null,
      isNewInterface: false,
      testElements: [
        {
          title: 'Название роли',
          value: 'Снабженец',
        },
        {
          title: 'Комментарий',
          value: 'Справочник + продукты',
        },
      ],
      isNewGroup: false,
      search: '',
      titleNewGroup: '',
      schemaData: [],
      selectedModule: null,
      modules: [
        {
          title: 'Система',
          value: 'system',
          childrens: [
            {
              title: 'Настройки',
              value: 'settings',
            },
            {
              title: 'Справочники',
              value: 'dictionaries',
            },
            {
              title: 'Продукты',
              value: 'products',
            },
            {
              title: 'Мероприятия',
              value: 'events',
            },
          ],
        },
        {
          title: 'Справочники',
          value: 'dictionaries',
          childrens: [
            {
              title: 'Динамические характеристики',
              value: 'dynamic_characteristics',
            },
            {
              title: 'Должности контактов',
              value: 'contact_positions',
            },
            {
              title: 'Типы организаций',
              value: 'organization_types',
            },
            {
              title: 'Статусы контактов и организаций',
              value: 'contact_organization_statuses',
            },
            {
              title: 'Бренды',
              value: 'brands',
            },
            {
              title: 'Типы цен',
              value: 'price_types',
            },
            {
              title: 'Города',
              value: 'cities',
            },
            {
              title: 'Страны',
              value: 'countries',
            },
            {
              title: 'Шоссе',
              value: 'highways',
            },
            {
              title: 'Регион',
              value: 'regions',
            },
            {
              title: 'Единицы измерения',
              value: 'units',
            },
          ],
        },
      ],
      newSchemaData: {
        data: [
          {
            //  Даныне сущности permission.
            id: 'AvgPq4zrJozb07yk', // permission.id
            title: 'Создавать',
            name: 'event-create',
            description: 'Предоставляет возможность создавать мероприятия.',
            assigned: false, // Назначино разрешение для роли или нет.
            schema: [],
          },
          {
            id: '7YmnlQzpVb2gDRrv',
            title: 'Видеть',
            name: 'event-read',
            description: 'Предоставляет возможность видеть мероприятия.',
            assigned: true,
            // Схема рендера доп. условий. В масиве может быть теоретически много блоков. Пока один главный (условия)
            schema: [
              {
                title: 'Условия', // Название блока
                type: 'block', // Тип блока (возможно потом какой нибудь inline будет)
                conditions: [
                  {
                    id: 'NxOpZowo9GmjKqdR', // Id сущности permission.condition если она уже сохранена (применена) в базе
                    type: 'groups', // Тип отображения условия
                    key: 'event.conditions', // Ключ условия для сохранения в базу данных условий
                    title: 'Группа', // Название условия (пока вроде всегда так будет)
                    // Сохранённые значения для селекта. ВАЖНО!!! Тут нужно УЧИТЫВАТЬ ГРУППЫ. Если Иван забыл сказать могу пояснить.
                    value: [
                      {
                        type: {
                          value: 'event_types',
                          title: 'Тип мероприятий',
                        },
                        values: [
                          {
                            value: 'auth',
                            title: 'Текущий пользователь',
                          },
                          {
                            value: 3,
                            title: 'Vistor (79272221119)',
                          },
                        ],
                      },
                      {
                        type: {
                          value: 'created_by',
                          title: 'Кто создал',
                        },
                        values: {
                          value: 3,
                          title: 'Vistor (79272221119)',
                        },
                      },
                    ],
                    // Список доступных списков для отображения в контексте данного условия
                    allowed_lists: [
                      {
                        title: 'Тип мероприятий',
                        key: 'event_types', // Ключ для сохранения в базу
                        from: 'event_types', // Значение - название списка берётся из custom.lists.event_types
                        multiple: true, // Множественное или нет
                      },
                      {
                        title: 'Кто создал',
                        key: 'created_by',
                        from: 'users',
                        multiple: false,
                      },
                      {
                        title: 'Участник',
                        key: 'participant',
                        from: 'humans',
                        multiple: true,
                      },
                      {
                        title: 'Ответственный',
                        key: 'responsible_by',
                        from: 'users',
                        multiple: true,
                      },
                    ],
                  },
                ],
              },
              {
                title: 'Запреты', // Название блока
                type: 'block', // Тип блока (возможно потом какой нибудь inline будет)
                conditions: [],
              },
            ],
          },
        ],
        custom: {
          // Доступные списки
          lists: {
            users: {
              title: 'Пользователи',
              api: '/v1/users', // Апи для погрузки данных. Изначально подгружаются по 10 записей макс. Остальное уже как поиск через селект
              options: [
                {
                  value: 'auth',
                  title: 'Текущий пользователь',
                },
                {
                  value: 1,
                  title: 'Super Admin',
                },
                {
                  value: 3,
                  title: 'Sergeyuutyret',
                },
                {
                  value: 4,
                  title: 'Sergeyuutyret',
                },
                {
                  value: 5,
                  title: 'Sergeyuutyret',
                },
                {
                  value: 6,
                  title: 'Sergey',
                },
              ],
            },
            humans: {
              title: 'Люди',
              api: '/v1/humans',
              options: [
                {
                  value: 'auth',
                  title: 'Текущий пользователь',
                },
                {
                  value: 1,
                  title: 'Super Admin',
                },
                {
                  value: 2,
                  title: 'Vistor (79272221112)',
                },
                {
                  value: 3,
                  title: 'Vistor (79272221119)',
                },
                {
                  value: 4,
                  title: 'Vistor (79262221119)',
                },
                {
                  value: 5,
                  title: 'Sergeyuutyret',
                },
                {
                  value: 7,
                  title: 'тест (79067918529)',
                },
                {
                  value: 12,
                  title: 'Калинин Тест тест тест (79521169464)',
                },
                {
                  value: 13,
                  title: 'Тестовый для ERP (79000022222)',
                },
                {
                  value: 15,
                  title: 'Sergeyuutyret',
                },
                {
                  value: 16,
                  title: 'Sergey',
                },
              ],
            },
            event_types: {
              title: 'Тип мероприятий',
              api: '/v1/event-types',
              options: [
                {
                  value: 1,
                  title: 'Замер',
                },
                {
                  value: 2,
                  title: 'ПОЗР',
                },
                {
                  value: 3,
                  title: 'ГО',
                },
                {
                  value: 4,
                  title: 'Работы',
                },
                {
                  value: 5,
                  title: 'Консультация дизайнера',
                },
                {
                  value: 6,
                  title: 'Встреча',
                },
                {
                  value: 7,
                  title: 'Гарантийные работы',
                },
                {
                  value: 8,
                  title: 'Skype',
                },
                {
                  value: 9,
                  title: 'Выходной',
                },
                {
                  value: 10,
                  title: 'Съемки',
                },
              ],
            },
            event_columns: {
              title: 'Поля мероприятия',
              api: null,
              options: [
                {
                  title: 'Дата создания',
                  value: 'created_at',
                },
                {
                  title: 'core.created_by',
                  value: 'created_by',
                },
              ],
            },
          },
        },
      },
      testOptions: [
        {
          title: 'Создавать',
          value: true,
        },
        {
          title: 'Отображать',
          value: false,
        },
        {
          title: 'Изменять',
          value: false,
        },
        {
          title: 'Удалять',
          value: false,
        },
        {
          title: 'Экспортировать',
          value: false,
        },
      ],
      options: [
        {
          title: 'Создавать',
          value: true,
        },
        {
          title: 'Отображать',
          value: false,
        },
        {
          title: 'Изменять',
          value: false,
        },
        {
          title: 'Удалять',
          value: false,
        },
        {
          title: 'Экспортировать',
          value: false,
        },
      ],
      loading: false,
      roleDisabled: true,
    }
  },
  watch: {
    currentItem() {
      this.roleDisabled = this.currentItem.name === this.$store.state.user.role
    },
  },
  computed: {
    filteredModules() {
      return this.search
        ? this.schemaData
            .map(item => {
              const filteredChildrens = item.containers.filter(child =>
                child.container.toLowerCase().includes(this.search.toLowerCase())
              )
              return filteredChildrens.length ? {...item, containers: filteredChildrens} : null
            })
            .filter(item => item !== null)
        : this.schemaData
    },
  },
  methods: {
    closeAcc,
    closeRightSidebar() {
      this.edit = false
      this.newElement = false
      eventBus.$emit('new-state', false)
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
    resetAll() {
      this.search = ''
      this.selectedModule = null
    },
    removeCondition(dataIndex, schemaIndex, conditionIndex, valueIndex) {
      this.newSchemaData.data[dataIndex].schema[schemaIndex].conditions[conditionIndex].value.splice(valueIndex, 1)
    },
    addCondition(dataIndex, schemaIndex, conditionIndex) {
      this.newSchemaData.data[dataIndex].schema[schemaIndex].conditions[conditionIndex].value.push({
        type: this.newSchemaData.data[dataIndex].schema[schemaIndex].conditions[conditionIndex].allowed_lists.map(
          item => ({title: item.title, value: item.key})
        )[0],
        values: [],
      })
    },
    addGroup(dataIndex, schemaIndex) {
      this.newSchemaData.data[dataIndex].schema[schemaIndex].conditions.push({
        type: 'groups',
        title: this.titleNewGroup,
        value: [],
        allowed_lists: [
          {
            title: 'Тип мероприятий',
            key: 'event_types', // Ключ для сохранения в базу
            from: 'event_types', // Значение - название списка берётся из custom.lists.event_types
            multiple: true, // Множественное или нет
          },
          {
            title: 'Кто создал',
            key: 'created_by',
            from: 'users',
            multiple: false,
          },
          {
            title: 'Участник',
            key: 'participant',
            from: 'humans',
            multiple: true,
          },
          {
            title: 'Ответственный',
            key: 'responsible_by',
            from: 'users',
            multiple: true,
          },
        ],
      })
      this.isNewGroup = false
      this.titleNewGroup = ''
    },
    selectModule(value) {
      this.selectedModule = value
    },
  },
}
</script>

<style lang="scss">
.details-form__info-container {
  .zem-input {
    margin-top: 0;
  }
}

.accesses-container {
  > .zem-card:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
      display: flex;

      img:not(:first-child) {
        margin-left: 8px;
      }

      .block-id__delete {
        width: 17px;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.accesses-container {
  height: 100%;
  padding: 20px;
  overflow-y: auto;

  .block-id {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-mine-shaft;

    &__edit {
      cursor: pointer;
    }

    &__label {
      width: 6px;
      height: 6px;
      background: #2eb67d;
      border-radius: 100px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.details-form {
  &__title-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
    margin-right: 20px;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-mine-shaft;
    flex: 1;
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__info-container:not(:first-child) {
    margin-top: 6px;
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 90px 20px 20px 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>
